<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Children</h3>
    <ChildrenYN
        v-model="form.children_have"
        :children="form.children"
        :number-of-children="form.profileChildrenNumber"
        @numberOfChildren="form.profileChildrenNumber=$event"
    />
    <div v-if="form.children_have" class="question-box with-arrow">
      <ChildrenShort
          v-model="form.children"
          :number-of-children="form.profileChildrenNumber"
          @numberOfChildren="form.profileChildrenNumber=$event"
          @refresh="refresh"
          ref="childrenShort"
      />
    </div>
    <Pregnant v-model="form.profilePregnant"/>
    <div v-if="form.profilePregnant" class="question-box with-arrow">
      <PregnantDate v-model="form.profilePregnantDueDate"/>
    </div>
    <h3 class="fieldset-heading">Dependants</h3>
    <DependantsYN
        v-model="form.dependants_have"
        :dependants="form.dependants"
        :number-of-dependants="form.profileDependantsNumber"
        @numberOfDependants="form.profileDependantsNumber=$event"
    />
    <div v-if="form.dependants_have" class="question-box with-arrow">
      <DependantsShort
          v-model="form.dependants"
          :number-of-dependants="form.profileDependantsNumber"
          @numberOfDependants="form.profileDependantsNumber=$event"
          @refresh="refresh"
          ref="dependantsShort"
      />
    </div>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import ChildrenYN from "../../question/questions/clientProfile/people/ChildrenYN";
import ChildrenShort from "../../question/questions/clientProfile/people/ChildrenShort";
import DependantsYN from "../../question/questions/clientProfile/people/DependantsYN";
import DependantsShort from "../../question/questions/clientProfile/people/DependantsShort";
import Pregnant from "../../question/questions/clientProfile/people/Pregnant";
import PregnantDate from "../../question/questions/clientProfile/people/PregnantDate";


export default {
  name: 'ChildrenDependantsEstatePlanning',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    PregnantDate,
    Pregnant,
    DependantsShort,
    DependantsYN,
    ChildrenShort,
    ChildrenYN

  },
  data() {
    return {
      label: 'Dependants' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    refresh() {
      if ('childrenShort' in this.$refs) {
        this.$refs.childrenShort.get()
      }
      if ('dependantsShort' in this.$refs) {
        this.$refs.dependantsShort.get()
      }
    }
  }
};
</script>
