<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import AssetsFull from "../../../components/common/questionnaires/fieldsets/full/AssetsFull.vue";
import LiabilitiesFull from "../../../components/common/questionnaires/fieldsets/full/LiabilitiesFull";
import Executors from "../../../components/common/questionnaires/fieldsets/will/Executors";
import Trustees from "../../../components/common/questionnaires/fieldsets/will/Trustees";
import Guardians from "../../../components/common/questionnaires/fieldsets/will/Guardians";
import Pets from "../../../components/common/questionnaires/fieldsets/will/Pets";
import Funeral from "../../../components/common/questionnaires/fieldsets/will/Funeral";
import ContactEstatePlanning from "@/components/common/questionnaires/fieldsets/estatePlanning/ContactEstatePlanning";
import NameStatusEstatePlanning
  from "@/components/common/questionnaires/fieldsets/estatePlanning/NameStatusEstatePlanning";
import AddressEstatePlanning from "@/components/common/questionnaires/fieldsets/estatePlanning/AddressEstatePlanning";
import ChildrenDependantsEstatePlanning
  from "@/components/common/questionnaires/fieldsets/estatePlanning/ChildrenDependantsEstatePlanning";
import Gifts from "../../../components/common/questionnaires/fieldsets/will/Gifts";

export default {
  name: "WillShort",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    ContactEstatePlanning,
    NameStatusEstatePlanning,
    AddressEstatePlanning,
    ChildrenDependantsEstatePlanning,
    AssetsFull,
    LiabilitiesFull,
    Executors,
    Trustees,
    Guardians,
    Pets,
    Funeral,
    Gifts
  },
  data() {
    return {
      name: 'WillShort',
      title: "Profile & Will Instruction Demo",
      url: 'will_short',
      order:
          [
            'NameStatusEstatePlanning',
            'AddressEstatePlanning',
            'ChildrenDependantsEstatePlanning',
            'AssetsFull',
            'LiabilitiesFull',
            'Executors',
            'Trustees',
            'Guardians',
            'Gifts',
            'Pets',
            'Funeral'
          ],
      expiryAccessCodeOnFinish: true
    }
  }
};
</script>
